import { Button, Loader, Textarea } from 'src/design-system'
import * as React from 'react'
import { store } from 'store/index'
import { ReflectionResults } from './reflection-results'
import { errorToast } from 'app/packs/src/utils/error-toast'
import { Toaster } from 'react-hot-toast'
import { ExtractedReflectionAnalysisOutput } from 'store/modules/async-tasks'
import { trackEvent } from '../../services/event-tracker'

export const ReflectionForm = () => {
  const [content, setContent] = React.useState('')
  const [loading, setLoading] = React.useState(false)
  const [analysisResult, setAnalysisResult] = React.useState<
    ExtractedReflectionAnalysisOutput[] | undefined
  >()

  const analyzeReflection = async () => {
    if (!content) {
      alert('Please enter some content first.')
      return
    }

    setLoading(true)

    trackEvent('$track_reflection_created', {
      source: 'in_app',
      length: content.length,
    })

    await store.reflections.create({
      content,
    })

    const activeTask = await store.asyncTasks.create({
      type: 'open_ai',
      step: 'reflection-analysis',
      attributes: {
        content: content,
      },
    })

    let taskId: string

    if (activeTask.success && activeTask?.data?.asyncTasks) {
      taskId = Object.keys(activeTask.data.asyncTasks)[0]

      await store.asyncTasks.byId(taskId)?.pollOne()
      const asyncTask = store.asyncTasks.byId(taskId)
      const output = asyncTask?.output
      setAnalysisResult(output?.reflectionAnalysis?.output?.extracted)
      setLoading(false)
    } else {
      errorToast('Sorry there was a problem, please try again')
    }
  }
  return (
    <>
      {!analysisResult && (
        <>
          <div className="text-gray-900 font-bold mb-2">
            Write a short summary of your week
          </div>
          <Textarea
            className="w-full h-40 mb-4"
            value={content}
            onChange={(e) => setContent(e.target.value)}
            placeholder="Take a moment to share what went well, any challenges you encountered, how you overcame them, and what could you have done differently. Don't forget to highlight great moments of collaboration and acknowledge your teammates."
          />
          <div className="flex flex-col items-end">
            <Button onClick={analyzeReflection} disabled={loading}>
              {loading ? 'Working...' : 'Add reflection'}
              {loading && <Loader className="w-4 h-4" variant="paper" />}
            </Button>
          </div>
        </>
      )}

      {!loading && analysisResult && analysisResult[0].items && (
        <ReflectionResults
          originalContent={content}
          items={analysisResult[0].items}
        />
      )}
    </>
  )
}
