import * as React from 'react'
import reactStringReplace from 'react-string-replace'
import { ReflectionItem, ReflectionType } from './item'
import { ReflectionPoint } from 'store/modules/async-tasks'

export type ReflectionResultsProps = {
  originalContent: string | React.ReactNode[]
  items: ReflectionPoint[]
}

export type ReflectionItemProps = {
  content: string
  category: ReflectionType
  names?: string[]
  score?: number
}

export const ReflectionResults = (props: ReflectionResultsProps) => {
  const { items, originalContent } = props

  let highlighted = originalContent

  items.map((item) => {
    highlighted = reactStringReplace(
      highlighted,
      item.content.replace(/\./g, ''),
      (match, i) => (
        <ReflectionItem
          type={item.category}
          key={match + i}
          contentType={
            item.category === 'win' ? 'winsSuggestion' : 'actionsSuggestion'
          }
          inputs={{ content: item.content }}
          userNames={item.names?.map((n) => n.trim())}
          source="temp-reflection-ai"
        >
          {match}
        </ReflectionItem>
      )
    )
  })

  return (
    <div className="flex w-full flex-col">
      <div className="text-gray-900 font-bold mb-2">
        We have highlighted some potential wins and actions for you to consider
      </div>
      {highlighted && (
        <div className="w-full mb-8">
          <div className="w-full leading-relaxed whitespace-pre-wrap">
            {highlighted}
          </div>
        </div>
      )}

      <div className="w-full leading-relaxed text-gray-700">
        <a
          href="https://airtable.com/appRRZn27XSjeKA84/shrt1rleyWiWzUTiR"
          target="_blank"
          rel="noreferrer"
        >
          Did you find this useful? Feedback or suggestions? Click here to let
          us know.
        </a>
      </div>
    </div>
  )
}
