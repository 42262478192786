import * as React from 'react'
import { Avatar, Banner, Link, Tag } from 'src/design-system'
import { useStore } from 'store/context'
import { observer } from 'mobx-react-lite'
import { Info } from '@phosphor-icons/react'
import { formatDate } from '../../utils/date-helpers'

type ReflectionsShowPageProps = {
  reflectionId: string
}

export const ReflectionsShowPage = observer(
  (props: ReflectionsShowPageProps) => {
    const { reflectionId } = props
    const { reflections, currentUser } = useStore()
    const user = currentUser

    const reflection = reflections.byId(reflectionId)

    if (!user || !reflection) return null

    return (
      <section className="h-full">
        <div className="flex flex-row h-full w-full">
          <div className="flex-1 max-w-full">
            <Banner variant="warning">
              <Info className="mr-1" /> Reflections is in alpha testing.{' '}
              <Link
                href="https://airtable.com/appRRZn27XSjeKA84/shrt1rleyWiWzUTiR"
                target="_blank"
              >
                Give feedback
              </Link>
            </Banner>
            <div className="my-12 px-20 w-full max-w-4xl m-auto">
              <div className="flex flex-row">
                <div className="mr-2">
                  <Avatar
                    alt={`Avatar for ${user.fullName}`}
                    className="border-gray-100 border-px border-solid -ml-1"
                    fallbackText={user.initials}
                    size="md"
                    src={user.avatarUrl}
                  />
                </div>
                <div className="flex flex-col">
                  <div className="text-gray-800 text-xl font-bold w-full">
                    Weekly reflection{' '}
                    <Tag className="inline" variant="warning">
                      Alpha
                    </Tag>
                  </div>
                  <div className=" text-gray-600">
                    <time
                      dateTime={reflection.createdAt.toISOString()}
                      className="text-xs leading-none"
                    >
                      {formatDate(reflection.createdAt)}
                    </time>
                  </div>
                </div>
              </div>
              <div className="mt-4">{reflection.content}</div>
            </div>
          </div>
        </div>
      </section>
    )
  }
)
