import { observer } from 'mobx-react-lite'
import * as React from 'react'
import { User } from 'store/modules/users'
import { AvatarButtonGroup, Banner, Tooltip } from 'src/design-system'
import { ExternalActivity } from 'store/modules/external-activities'
import googleCalendar from '../../../../images/google-calendar.png'
import linear from '../../../../images/linear.png'
import { avatarGroupProps } from 'app/packs/src/utils/user-helpers'
import { compareAsc } from 'date-fns'
import { IntegrationCard } from 'pages/integrations-page/integration-card'
import GoogleCalendarIcon from '../../../pages/integrations-page/icons/google-calendar-icon.svg'
import LinearAppIcon from '../../../pages/integrations-page/icons/linear-app-icon.svg'

type ExternalActivitiesSectionProps = {
  externalActivities: ExternalActivity[]
  user: User
  type: 'google_calendar_event' | 'linear_app_issue_completed'
  connected: boolean | null
}

const activitiesMap = {
  google_calendar_event: {
    title: 'Meetings',
    iconSource: googleCalendar,
    connectTitle: 'Google Calendar',
    connectDescription:
      'Access your scheduled meetings to discover valuable opportunities for reflection.',
    connectHref: '/users/auth/google_services',
    disconnectHref: '/users/oauth_providers/google_services',
    connectIcon: GoogleCalendarIcon,
    emptyState:
      'Thanks for connecting your Google Calendar! We will show your weekly meetings here once they are available.',
    source: 'reflection-page',
  },
  linear_app_issue_completed: {
    title: 'Completed work',
    iconSource: linear,
    connectTitle: 'Linear',
    connectDescription:
      'By connecting Linear, you can unlock the power of focused reflection on specific events.',
    connectHref: '/users/auth/linear_app?return_url=/reflections',
    disconnectHref: '/users/oauth_providers/linear_app',
    connectIcon: LinearAppIcon,
    emptyState:
      'Thanks for connecting your Linear account! We will show completed issues here once they are available.',
    source: 'reflection-page',
  },
}

export const ExternalActivitiesSection = observer(
  (props: ExternalActivitiesSectionProps) => {
    const { externalActivities, user, type, connected } = props

    if (!user || connected === null) return null

    return (
      <>
        <div className="p-6 pl-0 pb-6">
          <div data-element-id="actions-section" className="pl-6">
            <div className="flex items-center justify-between mb-2">
              <div className="font-bold text-base text-gray-900">
                {activitiesMap[type].title}
              </div>
            </div>
            {!connected && (
              <IntegrationCard
                name={activitiesMap[type].connectTitle}
                description={activitiesMap[type].connectDescription}
                connect_href={activitiesMap[type].connectHref}
                disconnect_href={activitiesMap[type].disconnectHref}
                connected={false}
                icon={activitiesMap[type].connectIcon}
                source={activitiesMap[type].source}
              />
            )}
            {connected && externalActivities.length == 0 && (
              <Banner variant="info" className="rounded">
                {activitiesMap[type].emptyState}
              </Banner>
            )}
            {connected &&
              externalActivities.length > 0 &&
              externalActivities
                .sort((a, b) =>
                  compareAsc(a.performedAt as Date, b.performedAt as Date)
                )
                .map((activity) => (
                  <Tooltip
                    key={activity.id}
                    content={activity.title}
                    disabled={activity.title.length < 25}
                  >
                    <div className="flex items-center justify-between mb-4">
                      <div className="flex items-center justify-start gap-2 truncate flex-grow ">
                        <img
                          src={activitiesMap[type].iconSource}
                          alt=""
                          className="w-4"
                        />
                        <span className="truncate">{activity.title}</span>
                      </div>
                      <AvatarButtonGroup
                        avatars={avatarGroupProps(activity.actors, {
                          href: false,
                          stateTooltip: true,
                        })}
                        className="mr-1 ml-2"
                        size="xs"
                        limitStyle="tag"
                        limit={2}
                      />
                    </div>
                  </Tooltip>
                ))}
          </div>
        </div>
      </>
    )
  }
)
