import { ReflectionForm } from 'components/reflections/reflection-form'
import * as React from 'react'
import { store } from 'store/index'
import { Avatar, Tag } from 'src/design-system'
import { useStore } from 'store/context'
import { ActionsSection } from 'components/growth-side-panel/actions-section'
import { FocusSkillsSection } from 'components/growth-side-panel/focus-skills-section'
import { ExternalActivitiesSection } from 'components/growth-side-panel/external-activities-section'
import { observer } from 'mobx-react-lite'
import { startOfWeek, endOfWeek } from 'date-fns'

export const ReflectionsNewPage = observer(() => {
  const user = store.currentUser
  const { actions, currentUser, userSkills, externalActivities } = useStore()

  const [hasLinearConnection, setHasLinearConnection] = React.useState<
    boolean | null
  >(null)
  const [hasGoogleConnection, setHasGoogleConnection] = React.useState<
    boolean | null
  >(null)

  const fetchActions = React.useCallback(async () => {
    if (currentUser)
      await actions.fetchForUser(currentUser.id, { completed: false })
  }, [actions, currentUser])

  const fetchExternalActivities = React.useCallback(async () => {
    if (currentUser) {
      const { meta } = await store.externalActivities.fetchAll({
        include: ['actors'],
        page: { size: 100 },
        filter: {
          performedAtFrom: startOfWeek(new Date()),
          performedAtTo: endOfWeek(new Date()),
        },
      })
      setHasLinearConnection(meta?.linear_connection as boolean)
      setHasGoogleConnection(meta?.google_connection as boolean)
    }
  }, [currentUser])

  React.useEffect(() => {
    fetchActions()
    fetchExternalActivities()
  }, [fetchActions, fetchExternalActivities])

  if (!user) return null

  return (
    <section className="h-full">
      <div className="flex flex-row h-full w-full">
        <div className="flex-1 max-w-full">
          <div className="my-12 px-20 w-full max-w-4xl m-auto">
            <div className="flex flex-row">
              <div className="mr-2">
                <Avatar
                  alt={`Avatar for ${user.fullName}`}
                  className="border-gray-100 border-px border-solid -ml-1"
                  fallbackText={user.initials}
                  size="md"
                  src={user.avatarUrl}
                />
              </div>
              <div className="text-gray-800 text-xl font-bold w-full">
                Weekly reflection{' '}
                <Tag className="inline" variant="warning">
                  Alpha
                </Tag>
              </div>
            </div>
            <p className="text-gray-600 mt-4 mb-10 text-sm">
              Weekly reflection is important as it fosters self-awareness,
              learning from experiences, and continuous personal growth.
            </p>
            <ReflectionForm />
          </div>
        </div>

        <div className="border-0 border-gray-100 border-l border-solid h-full overflow-auto relative w-[420px] z-20">
          <div className="flex flex-col lg:fixed lg:h-screen lg:overflow-auto w-[420px]">
            <ExternalActivitiesSection
              type="linear_app_issue_completed"
              externalActivities={externalActivities.getTypeForUserInCurrentWeek(
                'linear_app_issue_completed',
                user.id
              )}
              user={user}
              connected={hasLinearConnection}
            />
            <ExternalActivitiesSection
              type="google_calendar_event"
              externalActivities={externalActivities.getTypeForUserInCurrentWeek(
                'google_calendar_event',
                user.id
              )}
              user={user}
              connected={hasGoogleConnection}
            />
            <FocusSkillsSection
              focusedUserSkills={userSkills.focusedForUser(user.id)}
              suggestedUserSkills={userSkills.suggestedForUser(user.id)}
              user={user}
            />
            <ActionsSection
              actions={actions.incompleteForUser(user.id)}
              focusSkillIds={userSkills.focusedSkillIdsForUser(user.id)}
              user={user}
              userSkills={userSkills.forUser(user.id)}
            />
          </div>
        </div>
      </div>
    </section>
  )
})
