import React from 'react'
import cn from 'classnames'
import { Button, Loader, Tooltip, useModalContext } from 'src/design-system'
import {
  CircleWavyCheck,
  Checks,
  Megaphone,
  Icon,
  Plus,
} from '@phosphor-icons/react'
import { AiVmMap, AiVmMapKey } from 'components/ai/content/map'
import { store } from 'store/index'
import { CreateFormProps } from 'components/create-form'
import { CREATE_FORM_MODAL_ID } from 'components/create-form-modal/utils'
import { trackEvent } from '../../services/event-tracker'

export type ReflectionType = 'win' | 'action' | 'feedback'

const typeMap: Record<
  ReflectionType,
  {
    bg: string
    hover: string
    title: string
    fg: string
    border: string
    icon: Icon
  }
> = {
  win: {
    bg: 'bg-green-100',
    hover: 'hover:bg-green-200',
    title: 'Win',
    fg: 'text-green-700',
    border: 'border-green-600',
    icon: CircleWavyCheck,
  },
  action: {
    bg: 'bg-blue-100',
    hover: 'hover:bg-blue-200',
    title: 'Action',
    fg: 'text-blue-700',
    border: 'border-blue-600',
    icon: Checks,
  },
  feedback: {
    bg: 'bg-yellow-100',
    hover: 'hover:bg-yellow-200',
    title: 'Feedback',
    fg: 'text-yellow-700',
    border: 'border-yellow-600',
    icon: Megaphone,
  },
}

export const ReflectionItem: React.FC<{
  children: React.ReactNode
  type: ReflectionType
  contentType: AiVmMapKey
  source: string
  inputs: Record<string, unknown>
  userNames?: string[]
}> = ({ children, type, contentType, source, inputs, userNames }) => {
  const { bg, hover, title, fg, border, icon: Icon } = typeMap[type]

  const { openModal } = useModalContext()

  const [loading, setLoading] = React.useState(false)

  const aiVm = React.useMemo(() => {
    return new AiVmMap[contentType](source)
  }, [contentType, source])

  const onClick = async () => {
    setLoading(true)

    trackEvent('$track_reflection_item_clicked', {
      source: 'in_app',
      type,
    })

    const taskId = await aiVm.onSubmit({
      ...inputs,
    })

    if (taskId) {
      await store.asyncTasks.byId(taskId)?.pollOne()
      const task = store.asyncTasks.byId(taskId)

      setLoading(false)

      if (task?.isSuccess && store.currentUser) {
        if (contentType === 'winsSuggestion') {
          const userIds =
            userNames &&
            userNames.reduce((acc, name) => {
              const user = store.users.all.find((u) => u.fname === name)
              if (user) {
                acc.push(user.id)
              }
              return acc
            }, [] as string[])

          openModal<CreateFormProps>(CREATE_FORM_MODAL_ID, {
            initialContent:
              task.output?.winsSuggestion?.output?.extracted[0].items[0].text,
            source: 'reflection-win-suggestion',
            user: store.currentUser,
            initialUserIds: userIds,
            tabs: ['win'],
          })
        }

        if (contentType === 'actionsSuggestion') {
          const content =
            task.output?.actionsSuggestion?.output?.extracted[0].items
              .map((i) => `- ${i.text}`)
              .join('<br/>')

          openModal<CreateFormProps>(CREATE_FORM_MODAL_ID, {
            initialContent: content,
            source: 'reflection-action-suggestion',
            user: store.currentUser,
            tabs: ['action'],
          })
        }
      }
    }
  }

  return (
    <Tooltip
      content={
        <span className="flex gap-1 items-center">
          <Icon strokeWidth={1} size={15} />
          {title}
        </span>
      }
      side="top"
      className="inline"
    >
      <span
        onClick={onClick}
        className={cn(
          'px-0.5 py-[3px] -ml-0.5 mr-1 cursor-pointer align-middle rounded-sm',
          bg,
          hover
        )}
      >
        {children}
        <Button
          variant="outline"
          size="sm"
          className={cn(
            'inline-flex gap-0 items-center border-1 border-solid p-0.5 ml-1 -mt-0.5 text-xs align-middle leading-tight',
            fg,
            bg,
            border,
            hover
          )}
          onClick={onClick}
        >
          {loading && (
            <>
              <Loader className="w-3 h-3 inline-flex" /> Generating{' '}
            </>
          )}
          {!loading && (
            <>
              <Icon strokeWidth={1} size={15} /> Add{' '}
            </>
          )}
          {title} <Plus strokeWidth={1} size={10} />
        </Button>
      </span>
    </Tooltip>
  )
}
