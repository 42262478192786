import * as React from 'react'
import { store } from 'store/index'
import { Button, Link, Loader, Table } from 'src/design-system'
import { observer } from 'mobx-react-lite'
import { ArrowRight, Plus } from '@phosphor-icons/react'
import { formatDate } from '../../utils/date-helpers'
import { trackEvent } from '../../services/event-tracker'

export const ReflectionsIndexPage = observer(() => {
  const [loading, setLoading] = React.useState(true)

  React.useEffect(() => {
    const fetchReflections = async () => {
      await store.reflections.fetchAll({
        page: { size: 999 },
      })
    }
    fetchReflections()
    setLoading(false)
  }, [])

  return (
    <section className="h-full">
      <div className="flex flex-row h-full w-full">
        <div className="flex-1 max-w-full">
          <div className="my-12 px-20 w-full max-w-4xl m-auto">
            <ReflectionsAlphaBanner />

            <div className="flex flex-col">
              <div className="flex justify-between mb-6">
                <h3 className="text-base">
                  Your Reflections{' '}
                  <span className="font-normal text-sm ml-1">
                    {store.reflections.all.length}
                  </span>
                </h3>
                <Button
                  as="a"
                  data-turbo-action="advance"
                  href="/reflections/new"
                >
                  <Plus /> New Reflection
                </Button>
              </div>
              {loading ? (
                <Loader />
              ) : (
                <Table.Root className="border-0" tableClassNames="w-full">
                  <Table.Head>
                    <Table.Row>
                      <Table.Header className="text-sm !w-96 table-cell pl-0">
                        Date
                      </Table.Header>

                      <Table.Header className="text-sm !w-12 table-cell" srOnly>
                        Actions
                      </Table.Header>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body className="border-collapse bordered">
                    {store.reflections.all
                      .sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
                      .map((reflection) => {
                        return (
                          <Table.Row
                            key={reflection.id}
                            onClick={() => {
                              trackEvent('$reflection_view_clicked', {
                                reflection_id: reflection.id,
                              })
                            }}
                          >
                            <Table.Cell className="font-bold pl-0">
                              {formatDate(reflection.createdAt)}
                            </Table.Cell>

                            <Table.Cell>
                              <Link
                                href={`reflections/${reflection.id}`}
                                data-turbo-frame="content"
                                data-turbo-action="advance"
                              >
                                <ArrowRight />
                              </Link>
                            </Table.Cell>
                          </Table.Row>
                        )
                      })}
                  </Table.Body>
                </Table.Root>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  )
})

const ReflectionsAlphaBanner = () => (
  <div className="bg-blue-100 p-4 sm:p-6 rounded-lg w-full mb-8">
    <h2 className="mb-2 text-3xl">Turn reflection into action</h2>
    <p>
      Weekly reflections foster self-awareness, learning from experiences, and
      continuous personal growth. Progression makes them actionable.
    </p>

    <p className="mb-6">
      This is an alpha experience which is active development. Your thoughts and
      feedback will help us shape the future of it.
    </p>

    <div className="flex">
      <Button
        variant="outline"
        className="text-blue-700 border-blue-700"
        as="a"
        data-turbo-action="advance"
        href="https://airtable.com/appRRZn27XSjeKA84/shrt1rleyWiWzUTiR"
        target="_blank"
      >
        Give feedback
      </Button>
    </div>
  </div>
)
