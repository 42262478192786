import { forRails } from 'components/for-rails'
import { registerComponents } from '../src/utils/react/registry'
import { ReflectionsNewPage } from 'pages/reflections-new-page'
import { ReflectionsShowPage } from 'pages/reflections-show-page'
import { ReflectionsIndexPage } from 'pages/reflections-index-page'

registerComponents({
  ReflectionsNewPage: forRails(ReflectionsNewPage),
  ReflectionsShowPage: forRails(ReflectionsShowPage),
  ReflectionsIndexPage: forRails(ReflectionsIndexPage),
})
